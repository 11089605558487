import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import { i18n } from './plugins';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';

createApp(App)
  .use(i18n)
  .use(router)
  .use(VueLazyload, {
    lazyComponent: true,
  })
  .mount('#app');
