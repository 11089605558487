import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d50b444"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolio-item card border-0 p-3" }
const _hoisted_2 = { class: "shadow h-100" }
const _hoisted_3 = { class: "portfolio-image" }
const _hoisted_4 = { class: "portfolio-desc card-body" }
const _hoisted_5 = { href: "#" }
const _hoisted_6 = { class: "text-justify mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lazy_component = _resolveComponent("lazy-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_lazy_component, { onShow: _ctx.handler }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["onShow"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, [
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.description), 1)
      ])
    ])
  ]))
}