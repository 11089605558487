
import { defineComponent } from 'vue';
import AboutUs from './AboutUs.vue';
import FooterNavigation from './FooterNavigation.vue';
import HeaderNavigation from './HeaderNavigation.vue';
import OurHeadquarters from './OurHeadquarters.vue';
import WhatWeOffer from './WhatWeOffer.vue';

export default defineComponent({
  name: 'HomeComponent',
  components: { AboutUs, FooterNavigation, HeaderNavigation, OurHeadquarters, WhatWeOffer },
});
