
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderNavigation',
  computed: {
    displayMenu(): boolean {
      if (this.windowWidth >= 992) return false;

      return this.extendMenu;
    },
  },
  data() {
    return {
      windowWidth: 0,
      extendMenu: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setWindowSize);
  },
  methods: {
    setWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    toggleMenu() {
      this.extendMenu = !this.extendMenu;
    },
  },
});
