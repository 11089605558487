
import { defineComponent } from 'vue';
import Home from '@/components/Home.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    Home,
  },
});
