
import { defineComponent } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';

export default defineComponent({
  name: 'OurHeadquarters',
  components: { GoogleMap, Marker },
  data() {
    return {
      center: { lat: 25.059449, lng: 55.136505 },
      mapStyles: [
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#444444' }],
        },
        { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
        { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ color: '#F0AD4E' }, { lightness: 60 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#2C3E50' }, { visibility: 'on' }],
        },
      ],
      windowWidth: 0,
      extendMenu: false,
    };
  },
});
