import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { store } from '@/store';
import { i18n } from '@/plugins/i18n';

const SUPPORTED_LOCALES = ['en', 'ru', 'es', 'pt'];

function getLocaleRegex() {
  let reg = '';
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: `/:locale${getLocaleRegex()}?`,
    name: 'home',
    component: HomeView,
    beforeEnter(to, from, next) {
      const lng = to.params.locale as string;
      store.mutations.setLanguage(lng);
      i18n.global.locale = lng;
      next();
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
