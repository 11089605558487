
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterNavigation',
  computed: {
    year(): number {
      return new Date().getFullYear();
    },
  },
});
