import { reactive } from 'vue';

const store = {
  // Create a state with the reactive function we imported previously, this will manage the reactivity for us
  state: reactive({
    language: 'en',
    // If this is null is for the example,
    // of course you can initialize the
    // counter with 0 directly
  }),
  // This section will handle the getters
  getters: {
    getLanauge() {
      return store.state.language;
    },
  },
  // This section will manage the changes into the state
  mutations: {
    setLanguage(language: string) {
      store.state.language = language;
    },
  },
  // This section will manage the actions needed for our store
  /*   actions: {
    setLanauge() {
      store.state.language = '';
    },
  }, */
};

export { store };
