
import { defineComponent } from 'vue';
import PortfolioCard from './PortfolioCard.vue';

export default defineComponent({
  name: 'WhatWeOffer',
  components: {
    PortfolioCard,
  },
});
