import { createI18n } from 'vue-i18n';
import * as en from '@/assets/locales/en.json';
import * as es from '@/assets/locales/es.json';
import * as pt from '@/assets/locales/pt.json';
import * as ru from '@/assets/locales/ru.json';

const messages = {
  en,
  es,
  pt,
  ru,
};

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
});

export { i18n };
